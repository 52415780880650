type ParsedData = {
  project: any;
  canvasList: any[];
  processingParamsList: any[];
  assetsList: any[];
};
export class CloudFileParser {
  /**
   * 将云项目数据转换为XCS文件
   * @param parsedData 云项目数据
   * @returns XCS文件对象
   */
  static async assembleToXCSFile(parsedData: ParsedData): Promise<File> {
    const { project, canvasList, processingParamsList } = parsedData;

    const newCanvasList = await CloudFileParser.handleCanvasList(canvasList);
    const { selectedCanvasId, deviceInfo, appVersion, ua, meta, coverImage, minRequiredVersion } = project;
    // 构建XCS文件内容
    const xcsContent = {
      canvasId: selectedCanvasId,
      canvas: newCanvasList,
      extId: deviceInfo.extId,
      device: {
        id: deviceInfo.id,
        power: deviceInfo.power,
        data: {
          dataType: 'Map',
          value: processingParamsList.map((params: any) => {
            const { canvasId, ...rest } = params;
            return [
              canvasId,
              {
                ...rest,
                displays: {
                  dataType: 'Map',
                  value: rest.displays,
                },
              },
            ];
          }),
        },
        materialList: deviceInfo?.materialList || [],
        materialTypeList: deviceInfo?.materialTypeList || [],
      },
      version: appVersion,
      created: project.extendInfo?.created,
      modify: project.extendInfo?.modify,
      ua,
      meta,
      cover: coverImage,
      minRequiredVersion,
    };

    // 生成文件名
    const fileName = `${project.name || 'untitled'}`;
    console.log('xcsContent :>> ', xcsContent);

    // 将内容转换为File对象
    return CloudFileParser.generateFile(JSON.stringify(xcsContent), 'xcs', fileName);
  }

  static async generateFile(content: string, suffix: string, fileName?: string): Promise<File> {
    // 默认生成文件名
    const defaultFileName = `${CloudFileParser.generateFileName()}.${suffix}`;
    const finalFileName = fileName ? `${fileName}.${suffix}` : defaultFileName;

    // 将字符串转换为 Uint8Array
    const uint8Array = new TextEncoder().encode(content);

    // 创建 Blob 对象
    const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

    // 创建 File 对象
    const file = new File([blob], finalFileName, {
      type: 'application/octet-stream',
    });

    return file;
  }

  /**
   * 生成一个唯一的文件名。
   *
   * @returns {string} 返回由当前时间戳和随机字符串组成的唯一文件名。
   */
  static generateFileName(): string {
    const dateString = Date.now().toString();
    const randomStr = Array.from({ length: 8 }, () => ((Math.random() * 16) | 0).toString(16)).join('');
    return `${dateString}${randomStr}`;
  }

  // 处理canvasList- displays数组中的url
  static async handleCanvasList(canvasList: any[]) {
    const newCanvasList = await Promise.all(
      canvasList.map(async (canvas) => {
        const displays = canvas.displays;
        await Promise.all(
          displays.map(async (display: any) => {
            const { dPath, template, base64 } = display;
            const isRemoteResource = Parse.isRemoteResource(dPath || template || base64);
            console.log('isRemoteResource', isRemoteResource);
            if (isRemoteResource) {
              if (base64) {
                const realBase64 = await Parse.remoteUrlToBase64(base64);
                display.base64 = realBase64;
              } else if (dPath) {
                const realJson = await Parse.remoteUrlToJson(dPath);
                console.log('realJson', realJson);
                console.log('dPath', dPath);

                display.dPath = realJson.dPath;
              } else if (template) {
                const realJson = await Parse.remoteUrlToJson(template);
                console.log('template', template);
                console.log('realJson', realJson);
                display.template = realJson.template;
              }
            }
          }),
        );

        return canvas;
      }),
    );
    return newCanvasList;
  }
}

// url链接转base64
class Parse {
  static isRemoteResource(content: string) {
    const regex = /^(http|https):/i;
    // const regex = /^(http|https):.*\.axcs$/i;
    return regex.test(content);
  }

  static remoteUrlToBase64(url: string): Promise<string> {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob(); // 将响应数据转换为 Blob
      })
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }

  static async remoteUrlToJson(url: string) {
    const content = await fetch(url).then((res) => res.json());
    return content;
  }
}
